import React from "react";
import Img from "gatsby-image";
import { StaticQuery, graphql } from "gatsby";

const CardImage = ({ src, style }) => (
  <StaticQuery
    query={graphql`
      query {
        allImageSharp {
          edges {
            node {
              fixed(width: 300, height: 300) {
                ...GatsbyImageSharpFixed_withWebp_tracedSVG
                originalName
              }
            }
          }
        }
      }
    `}
    render={data => {
      const image = data.allImageSharp.edges.find(
        edge => edge.node.fixed.originalName === src
      );
      if (!image) {
        return null;
      }
      return <Img fixed={image.node.fixed} style={style} />;
    }}
  />
);
export default CardImage;

/** @jsx jsx */
import React from 'react';
import { Link } from 'gatsby';
import { Styled, jsx, Text } from 'theme-ui';

import Layout from '../components/layout';
import Image from '../components/image';
import SEO from '../components/seo';
import Hero from '../components/Hero';
import Header from '../components/header';
import LandingPageCard from '../components/LandingPageCard';

// FIXME: Don't hardcode colors
const green = '#219653';
const purple = '#BB6BD9';
const orange = '#F2994A';

const IndexPage = () => (
  <div>
    <Header disableHome disableBottomMargin />
    <Layout disableHeader>
      <Hero />
      <SEO title="Home" />

      {/* <LandingPageCard
        description="Just like Shazam... But it works only with one album."
        name="004 Skyforger"
        img="skyforger.png"
        color={orange}
        link="/page-2/"
        tags={['Audio', 'Audio Fingerprinting', 'Neural Networks']}
      />

      <LandingPageCard
        description="Helsinki Take Away Restaurant Analyzer"
        name="003 Korkeajännitys"
        img="korkeajannitys.jpg"
        color={purple}
        tags={['Clustering']}
      /> */}

      {/* <LandingPageCard
        name="002 Peloton"
        description="Helsinki City Bike Analytics Dashboard"
        img="peloton.jpg"
        color={green}
        tags={[
          'Analytics',
          'Time Series',
          'Clustering',
          'Anomaly Detection',
          'SQL',
          'Lambda',
        ]}
      /> */}

      <LandingPageCard
        name="001 Gala"
        description="Helsinki Neural Art Gallery."
        img="gala.png"
        color={orange}
        tags={['Art', 'Images', 'Neural Networks', 'Style Transform']}
        link={'/gala'}
      />
    </Layout>
  </div>
);

export default IndexPage;

import React, { useState } from 'react';
import { Flex, Box, Heading, Text } from 'theme-ui';
import { navigate } from 'gatsby';

import CardImage from './CardImage';

const Badge = ({ children }) => (
  <Box
    mr={2}
    mb={2}
    p={1}
    pl={2}
    pr={2}
    sx={{
      // backgroundColor: "#fff",
      borderRadius: '8px',
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: '#444',
    }}
  >
    <Text
      sx={{
        fontSize: 1,
      }}
    >
      {children}
    </Text>
  </Box>
);

const Badges = ({ tags }) => (
  <Flex
    ml={4}
    mb={4}
    sx={{
      flexWrap: 'wrap',
    }}
  >
    {tags && tags.map(tag => <Badge>{tag}</Badge>)}
  </Flex>
);

const LandingPageCard = props => {
  const [hover, setHover] = useState(false);

  const transitionDuration = '0.145s';
  const transitionCurve = 'cubic-bezier(.42,0,.58,1)';

  const handleCLick = e => {
    e.preventDefault();
    navigate(props.link);
  };
  return (
    <Flex
      // bg="#f8f8f8"
      sx={{
        flexDirection: ['column', 'row'],
        justifyContent: 'space-between',
        width: '100%',
        // height: "250px",
        borderColor: hover ? '#ddd' : '#ededed',
        borderWidth: '1px',
        borderRadius: '8px',
        borderStyle: 'solid',
        marginBottom: '48px',
        // "& * > a": {
        //   textDecoration: "none",
        // },
        cursor: hover ? 'pointer' : null,
        // Tailwind box shadow
        // boxShadow: hover
        //   ? "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)"
        //   : "none",
        transition: `border box-shadow ${transitionDuration} ${transitionCurve}`,
      }}
      onMouseOver={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onTouchStart={() => setHover(true)}
      onTouchEnd={() => setHover(false)}
      onClick={e => handleCLick(e)}
    >
      <Box
        sx={{
          width: ['100', '39%'],
          height: '250px',
          backgroundColor: '#818181',
        }}
      >
        <CardImage
          src={props.img}
          style={{
            width: '100%',
            height: '100%',
            filter: hover ? 'none' : 'saturate(30%)',
            transition: `filter ${transitionDuration} ${transitionCurve}`,
            // borderRadius: '4px',
          }}
          // sx={{
          //   filter: hover ? "grayscale(100%)" : "none",
          // }}
        />
      </Box>
      <Box pl={[null, null, 4]} pt={3} sx={{ flex: 1 }}>
        <Heading
          as="h2"
          ml={4}
          mb={4}
          sx={{
            textDecoration: 'none',
            marginBottom: 3,
            color: '#444',
          }}
        >
          {props.name}
        </Heading>
        <Text ml={4} mb={4}>
          {props.description}
        </Text>
        <Badges tags={props.tags} />
        {/* {props.tags} */}
      </Box>
      {/* <Box
        sx={{
          visibility: hover ? null : "hidden",
          width: "4px",
          backgroundColor: "#eee",
          borderRadius: [null, null, "0 4px 4px 0"],
        }}
      ></Box> */}
    </Flex>
  );
};

export default LandingPageCard;

import React from "react";

// const green = "#219653";
// const purple = "#BB6BD9";
// const orange = "#F2994A";
// const red = "#EB5757";

const purple = '#404788';
const darkDgreen = '#238A8D';
const lightGreen = '#55C667';

const HeroAnimation = () => {
  const circleRedius = 50;
  const nCircles = 3;

  const margin = 30;
  const canvasWidth = 374;
  const canvasHeight = 320;
  return (
    <div>
      <svg height={canvasHeight} width={canvasWidth}>
        <circle
          cx="50"
          cy="155"
          r={circleRedius}
          // stroke="#888"
          stroke-width="2"
          fill={purple}
        />
        <circle
          cx="180"
          cy="210"
          r={circleRedius}
          // stroke="#888"
          stroke-width="2"
          fill={darkDgreen}
        />
        <circle
          cx="300"
          cy="130"
          r={circleRedius}
          // stroke="#888"
          stroke-width="2"
          fill={lightGreen}
        />
      </svg>
    </div>
  );
};

export default HeroAnimation;

import React from 'react';
import { Text, Flex, Box } from 'theme-ui';

import HeroAnimation from './HeroAnimation';

const Hero = () => {
  return (
    <Flex
      sx={{
        flexDirection: ['column-reverse', null, 'row'],
        marginBottom: ['64px', null, '96px'],
        marginTop: [null, null, '64px'],
      }}
    >
      <Box
        p={2}
        // bg="#eee"
        sx={{
          width: ['100%', null, '39%'],
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <HeroAnimation />
      </Box>
      <Box
        p={[2, 4, 5]}
        // bg="rebeccapurple"
        // bg="#f1f1f1"
        sx={{
          width: ['100%', null, '61%'],
          height: ['300px', null, '400px'],
          color: '#fff',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <Text variant="heroSmall">Hi! I'm</Text>

        <Text variant="heroBig">Aleksi Leino</Text>
        <Text variant="heroSmall">
          And I like to build <strong>automagic data analysis tools</strong>{' '}
          <br /> with <strong>delightful user interfaces</strong>.
        </Text>
      </Box>
    </Flex>
  );
};

export default Hero;
